import { FileAttachment } from './API'
import { blankAsUndefined, unique } from './Utils'

export interface FileAttachmentCategory {
  name?: string,
  files: FileAttachment[]
}

export function splitToCategories(files: FileAttachment[]): FileAttachmentCategory[] {
  let result: FileAttachmentCategory[] = [];
  const categories = unique(files.map(file => blankAsUndefined(file.category)))
  categories.forEach(category => {
    result.push({
      name: category,
      files: files.filter(file => file.category === category)
    })
})
  return result;
}
